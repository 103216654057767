import { useEffect } from "react";
import React from 'react';
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
  Navigate
} from "react-router-dom";
import { allRoutes } from "./routes";
import error from "./pages/page404";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;
  const getComponent = (component: React.ComponentType<any>) => {
    return React.createElement(component, {});
  };

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag: HTMLMetaElement | null = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
      <Routes>
        {/* start English routes and default routes */}
        <Route path={"/*"} element={getComponent(error)} />
        <Route
          path={"/"}
          element={
            <Navigate replace to={"/en/home"} />
          }
        />
        <Route
          path={"/en"}
          element={
            <Navigate replace to={"/en/home"} />
          }
        />
        {allRoutes.map((route, index) => (
          <Route
            path={route.path}
            element={
              <Navigate replace to={"/en" + route.path } />
            }
          />
        ))}
        {allRoutes.map((route, index) => (
          <Route
            path={"/en" + route.path}
            element={getComponent(route.component)}
          />
        ))}
        {allRoutes.map((route, index) => (
          <Route
            path={"/en" + route.path + "/*"}
            element={getComponent(route.component)}
          />
        ))}
        {/* end English routes and default routes */}
        

        {/* start Hindi routes */}
        <Route
          path={"/hi"}
          element={
            <Navigate replace to={"/hi/home"} />
          }
        />
        {allRoutes.map((route, index) => (
          <Route
            path={"/hi" + route.path}
            element={getComponent(route.component)}
          />
        ))}
        {allRoutes.map((route, index) => (
          <Route
            path={"/hi" + route.path + "/*"}
            element={getComponent(route.component)}
          />
        ))}
        {/* end Hindi routes */}


        {/* start espanis routes */}
        <Route
          path={"/es"}
          element={
            <Navigate replace to={"/es/home"} />
          }
        />
        {allRoutes.map((route, index) => (
          <Route
            path={"/es" + route.path}
            element={getComponent(route.component)}
          />
        ))}
        {allRoutes.map((route, index) => (
          <Route
            path={"/es" + route.path + "/*"}
            element={getComponent(route.component)}
          />
        ))}
        {/* end espanis routes */}
      </Routes>
  );
}
export default App;
