import { FunctionComponent, useState, useEffect, useRef } from "react";
import { config } from "../config/config";
import PxInputField from "./PxInputField";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";
import AlertTitle from "@mui/material/AlertTitle";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";
export type FrameComponent13Type = {
  className?: string;
};

const FrameComponent13: FunctionComponent<FrameComponent13Type> = ({
  className = "",
}) => {
  const { t: i18n } = useTranslation();
  useEffect(() => {
    //console.log(config.apiUrl)
    const handleResize = () => {
      setIsMobile(window.innerWidth < 550);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 550);
  const [isContactFormActive, setIsContactFormActive] = useState(true);
  const [messageSent, setMessageSent] = useState(false);
  const [errorInSending, setErrorInSending] = useState(false);
  const [messageSentShare, setMessageSentShare] = useState(false);
  const [errorInSendingShare, setErrorInSendingSnare] = useState(false);
  const schema = yup
    .object({
      to: yup
        .string()
        .email(i18n("frameComponentsThirteen.ToEmailValid"))
        .required(i18n("frameComponentsThirteen.ToEmailRequired")),
      name: yup.string().required(i18n("frameComponentsThirteen.NameRequired"))
      .min(4,i18n("frameComponentsThirteen.NameMin4")),
      email: yup
        .string()
        .email(i18n("frameComponentsThirteen.EmailValid"))
        .required(i18n("frameComponentsThirteen.EmailRequired")),
      phone: yup
        .string()
        .required(i18n("frameComponentsThirteen.PhoneRequired"))
        .matches(/^[0-9]+$/, i18n("frameComponentsThirteen.PhoneMatch"))
        .min(10, i18n("frameComponentsThirteen.PhoneMin10"))
        .max(10, i18n("frameComponentsThirteen.PhoneMax10")),
      message: yup
        .string()
        .required(i18n("frameComponentsThirteen.MessageRequired"))
        .min(8, i18n("frameComponentsThirteen.MessageMin8")),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,  // Added reset function to reset the form after submission
  } = useForm({ resolver: yupResolver(schema) });
  const { register: registerShare, handleSubmit: handleSubmitShare } =
    useForm();
  const formRef = useRef<HTMLFormElement>(null); // Explicitly specify HTMLFormElement type

  const onSubmitShare = async (data: any) => {
    setMessageSentShare(false);
    try {
      const fixData = {
        name: "Anonymous",
        email: "guest@resai.com",
        message: "Shared Phone Number",
        to: config.env.contactTo,
      };
      // perform async request, for example, using fetch
      const sharedData = { ...fixData, ...data };
      const response = await fetch(config.apiUrl + "email/contact-us", {
        method: "POST",
        body: JSON.stringify({ req_param: sharedData }),
        headers: {
          "Content-Type": "application/json",
          locale: "en",
        },
      })
        .then((response) => {
          if (response.ok) {
            // console.log('success')
            setMessageSentShare(true);
            setErrorInSending(false);
          } else {
            // console.log('error from backend server')
            setMessageSentShare(false);
            setErrorInSending(true);
          }
        })
        .then((data) => {
          //for now no use response data
        })
        .catch((error) => {
          setMessageSentShare(false);
          setErrorInSending(true);
          // handle network errors or other exceptions
          console.error("An error occurred:", error.message);
        });
    } catch (error) {
      setMessageSentShare(false);
      setErrorInSending(true);
      console.error("Error submitting form:", error);
      // Handle error
    }
  };

  const onSubmit = async (data: any) => {
    try {
      setErrorInSending(false);
      setIsContactFormActive(false);
      // perform async request, for example, using fetch
      const response = await fetch(config.apiUrl + "email/contact-us", {
        method: "POST",
        body: JSON.stringify({ req_param: data }),
        headers: {
          "Content-Type": "application/json",
          locale: "en",
        },
      })
        .then((response) => {
          setIsContactFormActive(true);
          //check if response is successful
          if (response.ok) {
            setMessageSent(true);
            reset();  // Reset the form after successful submission
          } else {
            setMessageSent(false);
            setErrorInSending(true);
          }
        })
        .then((data) => {
          //for now no use response data
        })
        .catch((error) => {
          setMessageSent(false);
          setErrorInSending(true);
          setIsContactFormActive(false);
          // handle network errors or other exceptions
          console.error("An error occurred:", error.message);
        });
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error
    }
  };

  return (
    <div id="contactus"
      className={`self-stretch h-[auto] bg-gray-100 box-border overflow-auto1 shrink-0 flex flex-col items-start justify-start pt-[62px] px-0 pb-[55px] gap1-[55.5px] max-w-full z-[1] text-left text-base text-neutrals-n80 font-buttons-button-lg border-t-[1px] border-solid border-gainsboro mq750:gap-[28px] mq750:pt-[26px] mq750:pb-[23px] mq750:box-border mq1050:h-auto mq1050:pt-10 mq1050:pb-9 mq1050:box-border ${className}`}
    >
      <div className="grid grid-cols-2 mq1050:grid-cols-1 mq1050:px-[100px]  mq450:px-10 gap-[100px] self-stretch px-[150px]">
        <div>
          {" "}
          <div className="w-[460px] mq1050:w-[auto] flex flex-col items-start justify-start pt-0.5 px-0 pb-0 box-border min-w-[460px] max-w-full mq750:min-w-full mq1050:flex-1">
            <div className="self-stretch flex flex-col items-start justify-start gap-[40px] max-w-full mq450:gap-[20px]">
              <div className="self-stretch flex flex-col items-start justify-start gap-[24px]">
                <img
                  className="w-[132px] h-10 relative object-contain"
                  alt=""
                  src="/logo2@2x.png"
                />
                <div className="self-stretch relative leading-[26px]">{i18n("frameComponentsThirteen.AIPoweredResumeBuilder")}</div>
              </div>

              <form
                ref={formRef}
                method="post"
                onSubmit={handleSubmitShare(onSubmitShare)}
              >
                <div className="self-stretch flex flex-row items-start justify-start gap-[16px] max-w-full text-neutrals-n150 mq450:flex-wrap">
                  <div className="flex-1 flex flex-col items-start justify-start gap-[32px] max-w-full mq450:gap-[16px]">
                    <div className="self-stretch flex flex-col items-start justify-start gap-[8px] max-w-full">
                      <div className="w-[300px] mq1050:w-[500px] relative leading-[16px] capitalize font-semibold inline-block overflow-hidden text-ellipsis whitespace-nowrap">
                        {i18n("frameComponentsThirteen.WantToTalkUs")}
                      </div>

                      {isMobile ? (
                        ""
                      ) : (
                        //  <input
                        //  type="text"
                        //  className="border-2 border-gray-300 rounded-md w-full h-10 px-2 mt-2 text-sm text-black-900 focus:outline-none w-[300px]"
                        //  placeholder="Share your mobile number"
                        //   {...registerShare("phone")}
                        //   />
                        <TextField
                          {...registerShare("phone")}
                          className="[border:none] bg-[transparent] self-stretch h-14 font-captions-captions-rg font-semibold text-base text-neutrals-n40"
                          placeholder={i18n("frameComponentsThirteen.ShareFieldPlaceholderName")}
                          variant="outlined"
                          sx={{
                            "& fieldset": { borderColor: "#e2e2e3" },
                            "& .MuiInputBase-root": {
                              height: "56px",
                              backgroundColor: "#fff",
                              borderRadius: "6px",
                            },
                            "& .MuiInputBase-input": { color: "#919295" },
                          }}
                        />
                      )}
                      {messageSentShare ? (
                        <div className="m-auto1">
                          <Alert severity="success">
                            <AlertTitle>{i18n("frameComponentsThirteen.ShareSuccess")}</AlertTitle>
                            {i18n("frameComponentsThirteen.ShareSuccessMessage")}
                          </Alert>
                        </div>
                      ) : (
                        // <div>Thanks for sharing </div>
                        ""
                      )}
                      {errorInSendingShare ? (
                        <div className="m-auto1">
                          <Alert severity="error">
                            <AlertTitle>{i18n("frameComponentsThirteen.ShareError")}</AlertTitle>
                            {i18n("frameComponentsThirteen.ShareErrorMessage")}
                          </Alert>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="flex flex-col items-start justify-start z-[3] text-system-black">
                      {/* {isMobile ? ( */}
                      <div className="rounded-md bg-silver flex flex-row items-center justify-center py-2.5 px-[15px] border-[1px] border-solid border-neutrals-n20">
                        <div className="flex flex-row items-center justify-center gap-[8px]">
                          <div className="overflow-hidden flex flex-row items-start justify-start">
                            <img
                              className="h-4 w-4 relative object-cover"
                              alt=""
                              src="/group@2x.png"
                            />
                          </div>
                          <div className="flex flex-col items-start justify-start">
                            <div className="relative leading-[16px] capitalize font-semibold">
                            {i18n("frameComponentsThirteen.MessageOnWhatsapp")}
                            </div>
                          </div>
                        </div>
                      </div>
                       {/* ) : (
                          ""
                        )}  */}
                    </div>
                    <div className="w-[136px] flex flex-row items-start justify-between gap-[20px]">
                      <img
                        className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px]"
                        alt=""
                        src="/hugeiconsocialoutlinelinkedln.svg"
                      />
                      <img
                        className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px]"
                        alt=""
                        src="/hugeiconsocialoutlinefacebook.svg"
                      />
                      <img
                        className="h-6 w-6 relative min-h-[24px]"
                        alt=""
                        src="/social-links.svg"
                      />
                    </div>
                  </div>
                  {isMobile ? (
                    ""
                  ) : (
                    <div className="flex flex-col items-start justify-start pt-8 px-0 pb-0 text-center text-lg text-neutral-white ml-2">
                      <button
                        type="submit"
                        className="rounded-md bg-neutrals-n90 flex flex-row items-start justify-start py-[14px] px-6"
                      >
                        <div className="relative leading-[18px] font-semibold inline-block text-white min-w-[41px]">
                        {i18n("frameComponentsThirteen.ShareButtonName")}
                        </div>
                      </button>
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>

        <div>
          <form
            action=""
            method="post"
            role="form"
            style={{ display: isContactFormActive ? "block" : "none" }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <input
              type="hidden"
              value={config.env.contactTo}
              {...register("to")}
            />

            {messageSent ? (
              <div className="m-auto mb-5">
                {/* <div>Your message has been sent. Thank you!</div> */}
                <Alert severity="success">
                  <AlertTitle>{i18n("frameComponentsThirteen.ContactUsSuccess")}</AlertTitle>
                  {i18n("frameComponentsThirteen.ContactUsSuccessMessage")}
                </Alert>
              </div>
            ) : (
              ""
            )}
            {errorInSending ? (
              <div className="m-auto mb-5">
                <Alert severity="error">
                  <AlertTitle>{i18n("frameComponentsThirteen.ContactUsError")}</AlertTitle>
                  {i18n("frameComponentsThirteen.ContactUsErrorMessage")}
                </Alert>
              </div>
            ) : (
              ""
            )}
            <div className="flex-1 flex flex-col items-end justify-start gap-[40px] min-w-[377px] max-w-full mq450:min-w-full mq750:gap-[20px]">
              <div className="self-stretch flex flex-col items-start justify-start gap-[34px] mq750:gap-[17px]">
                <h1 className="m-0 relative text-13xl tracking-[-0.02em] leading-[32px] font-semibold font-captions-captions-rg text-system-black text-left mq450:text-lgi mq450:leading-[19px] mq750:text-7xl mq750:leading-[26px]">
                {i18n("frameComponentsThirteen.FeelfreeToGetInTouch")}
                </h1>

                <div className="self-stretch flex flex-col items-start justify-start gap-[24px]">
                  <div className="self-stretch flex flex-row items-start justify-start gap-[16px] mq750:flex-wrap">
                    <div className="flex-1 flex flex-col items-start justify-start gap-[8px] min-w-[183px]">
                      <div className="w-[34px] relative text-sm leading-[14px] capitalize font-semibold font-captions-captions-rg text-neutrals-n70 text-left inline-block overflow-hidden text-ellipsis whitespace-nowrap">
                      {i18n("frameComponentsThirteen.NameLabel")}
                      </div>
                      <TextField
                        id="name"
                        {...register("name")}
                        className="[border:none] bg-[transparent] self-stretch h-14 font-captions-captions-rg font-semibold text-base text-neutrals-n40"
                        placeholder={i18n("frameComponentsThirteen.NamePlaceholderName")}
                        variant="outlined"
                        sx={{
                          "& fieldset": { borderColor: "#e2e2e3" },
                          "& .MuiInputBase-root": {
                            height: "56px",
                            backgroundColor: "#fff",
                            borderRadius: "6px",
                          },
                          "& .MuiInputBase-input": { color: "#919295" },
                        }}
                      />
                      {errors.name && (
                        <div className="text-red-600">
                          {errors.name.message}
                        </div>
                      )}
                    </div>
                    <div className="flex-1 flex flex-col items-start justify-start gap-[8px] min-w-[183px]">
                      <div className="w-[30px] relative text-sm leading-[14px] capitalize font-semibold font-captions-captions-rg text-neutrals-n70 text-left inline-block overflow-hidden text-ellipsis whitespace-nowrap">
                        {i18n("frameComponentsThirteen.EmailLabel")}
                      </div>
                      <TextField
                        id="email"
                        {...register("email")}
                        className="[border:none] bg-[transparent] self-stretch h-14 font-captions-captions-rg font-semibold text-base text-neutrals-n40"
                        placeholder={i18n("frameComponentsThirteen.EmailPlaceholderName")}
                        variant="outlined"
                        sx={{
                          "& fieldset": { borderColor: "#e2e2e3" },
                          "& .MuiInputBase-root": {
                            height: "56px",
                            backgroundColor: "#fff",
                            borderRadius: "6px",
                          },
                          "& .MuiInputBase-input": { color: "#919295" },
                        }}
                      />
                      {errors.email && (
                        <div className="text-red-600">
                          {errors.email.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                    <div className="w-[171px] relative text-sm leading-[14px] capitalize font-semibold font-captions-captions-rg text-neutrals-n70 text-left inline-block overflow-hidden text-ellipsis whitespace-nowrap">
                      {i18n("frameComponentsThirteen.MobileLabel")}
                    </div>
                    <TextField
                      id="phone"
                      {...register("phone")}
                      className="[border:none] bg-[transparent] self-stretch h-14 font-captions-captions-rg font-semibold text-base text-neutrals-n40"
                      placeholder= {i18n("frameComponentsThirteen.MobilePlaceholderName")}
                      variant="outlined"
                      sx={{
                        "& fieldset": { borderColor: "#e2e2e3" },
                        "& .MuiInputBase-root": {
                          height: "56px",
                          backgroundColor: "#fff",
                          borderRadius: "6px",
                        },
                        "& .MuiInputBase-input": { color: "#919295" },
                      }}
                    />
                    {errors.phone && (
                      <div className="text-red-600">{errors.phone.message}</div>
                    )}
                  </div>
                  <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                    <div className="w-[49px] relative text-sm leading-[14px] capitalize font-semibold font-captions-captions-rg text-neutrals-n70 text-left inline-block overflow-hidden text-ellipsis whitespace-nowrap">
                      {i18n("frameComponentsThirteen.MessageLabel")}
                    </div>
                    <TextField
                      id="message"
                      {...register("message")}
                      className="[border:none] bg-[transparent] self-stretch h-28 font-captions-captions-rg font-semibold text-base text-neutrals-n40"
                      placeholder={i18n("frameComponentsThirteen.MessagePlaceholderName")}
                      variant="outlined"
                      sx={{
                        "& fieldset": { borderColor: "#e2e2e3" },
                        "& .MuiInputBase-root": {
                          height: "112px",
                          backgroundColor: "#fff",
                          borderRadius: "6px",
                        },
                        "& .MuiInputBase-input": { color: "#919295" },
                      }}
                    />
                    {errors.message && (
                      <div className="text-red-600">
                        {errors.message.message}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <button className="rounded-md bg-neutrals-n90 flex flex-row items-start justify-start py-[19px] px-[62px] text-center text-lg text-neutral-white">
              {i18n("frameComponentsThirteen.SendButtonName")}
              </button>
            </div>
          </form>
          <img
            id="processing"
            style={{ display: isContactFormActive ? "none" : "block" }}
            src="/processing.gif"
          />
        </div>
      </div>

      {/* mob */}
      <div className="self-stretch w1-[1356px]  h-[250px] mq1050:h-[560px] mq450:h-[840px] flex flex-row items-start1 justify-start1 mt-[55px] py-0 px-0 box-border max-w-full text-center1 text-xl   gap-[25px]">
        <div className="grid grid-cols-4 mq450:grid-cols-1 mq1050:gap-0 mq1050:grid-cols-2  gap-4 w-full items-center  justify-center  h-[auto]   self-stretch h-px relative box-border z-[1] border-t-[1px] border-b-[1px] border-solid border-gray-1000 mq1050:border-b-[0px]">
          <div className="col-span2-2 box-border border-r-[1px] border-solid border-gray-1000 pb-10 pt-10 mq450:pb-[20px] mq450:pt-[20px]">
            <div className="flex flex-col items-start justify-start py-0 pl-[40px] pl-0 gap-[25px] mq1050:border-b-[1px] border-solid border-gray-1000 mq1050:pb-10 mq1050:pt-10  mq450:pb-[20px] mq450:pt-[5px]">
              <div className="relative tracking-[0.08em] leading-[20px] uppercase font-semibold inline-block min-w-[73px] mq450:text-base mq450:leading-[16px]">{i18n("frameComponentsThirteenFooter.ResumeTitle")}</div>
              <div className="flex flex-col items-start justify-around gap-[8px] text-base">
                <div className="relative leading-[26px] inline-block min-w-[98px] ">
                {i18n("frameComponentsThirteenFooter.ResumeBuilder")}
                </div>
                <div className="relative leading-[26px] inline-block min-w-[119px]">
                {i18n("frameComponentsThirteenFooter.ResumeTemplates")}
                </div>
                <div className="relative leading-[26px] inline-block min-w-[105px]">
                {i18n("frameComponentsThirteenFooter.ResumeFormats")}
                </div>
                <div className="relative leading-[26px]">
                {i18n("frameComponentsThirteenFooter.ResumeProfessionalWriter")}
                </div>
              </div>
            </div>
          </div>

          <div className="box-border border-r-[1px] border-solid border-gray-1000 pb-10 pt-10 mq1050:border-b-[1px] mq450:pb-[20px] mq450:pt-[20px]">
            <div className="flex flex-col items-start justify-start py-0 pl-[40px] pl-0 gap-[25px]">
              <div className="relative tracking-[0.08em] leading-[20px] uppercase font-semibold mq450:text-base mq450:leading-[16px]">
              {i18n("frameComponentsThirteenFooter.CoverLetterTitle")}
              </div>
              <div className="flex flex-col items-start justify-start gap-[8px] text-base">
                <div className="relative leading-[26px] text-left">
                {i18n("frameComponentsThirteenFooter.WhatIsCoverLetter")}
                </div>
                <div className="relative leading-[26px]">
                {i18n("frameComponentsThirteenFooter.CoverLetterTemplates")}
                </div>
                <div className="relative leading-[26px]">
                {i18n("frameComponentsThirteenFooter.CoverLetterFormats")}
                </div>
                <div className="relative leading-[26px]">
                {i18n("frameComponentsThirteenFooter.HowToWriteCoverLetter")}
                </div>
              </div>
            </div>
          </div>

          <div className="box-border border-r-[1px] border-solid border-gray-1000 pb-10 pt-10 mq1050:border-b-[1px] mq450:pb-[20px] mq450:pt-[20px]">
            <div className="flex flex-col items-start justify-start py-0 pl-[70px] mq450:pl-[40px] pl-0 gap-[25px]">
              <div className="relative tracking-[0.08em] leading-[20px] uppercase font-semibold inline-block min-w-[87px] mq450:text-base mq450:leading-[16px]">
              {i18n("frameComponentsThirteenFooter.Support")}
              </div>
              <div className="flex flex-col items-start justify-start gap-[8px] text-base">
                <div className="relative leading-[26px] inline-block min-w-[39px]">
                {i18n("frameComponentsThirteenFooter.About")}
                </div>
                <div className="relative leading-[26px] inline-block min-w-[52px]">
                {i18n("frameComponentsThirteenFooter.Contact")}
                </div>
                <div className="relative leading-[26px] inline-block min-w-[122px]"> {i18n("frameComponentsThirteenFooter.TermCondition")}</div>
                <div className="relative leading-[26px] inline-block min-w-[86px]">
                {i18n("frameComponentsThirteenFooter.PrivacyPolicy")}
                </div>
              </div>
            </div>
          </div>

          <div className="box-border pb-10 pt-10 mq450:pb-[20px] mq450:pt-[20px]">
            <div className="flex flex-col items-start justify-start py-0 pl-[70px] mq450:pl-[40px] pl-0 gap-[25px] mq1050:border-b-[1px] ">
              <div className="relative tracking-[0.08em] leading-[20px] uppercase font-semibold inline-block min-w-[110px] mq450:text-base mq450:leading-[16px]">
              {i18n("frameComponentsThirteenFooter.Resources")}
              </div>
              <div className="flex flex-col items-start justify-start gap-[8px] text-base">
                <div className="relative leading-[26px] inline-block min-w-[29px]">
                {i18n("frameComponentsThirteenFooter.Blog")}
                </div>
                <div className="relative leading-[26px]">
                {i18n("frameComponentsThirteenFooter.JobInterviewGuides")}
                </div>
                <div className="relative leading-[26px]">
                {i18n("frameComponentsThirteenFooter.JobInterviewQuestions")}
                </div>
                <div className="relative leading-[26px] inline-block min-w-[111px]">
                {i18n("frameComponentsThirteenFooter.CareerResources")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* mob c */}

      {/* <div className="w-[1356px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full text-right text-xl">
        <div className="w-[1056px] flex flex-row items-start justify-start relative max-w-full">
          <div className="h-[292px] w-px absolute !m-[0] bottom-[-63px] left-[284px] box-border border-r-[1px] border-solid border-gray-1000" />
          <div className="h-[292px] w-px absolute !m-[0] right-[485px] bottom-[-63px] box-border border-r-[1px] border-solid border-gray-1000" />
          <div className="h-[292px] w-px absolute !m-[0] right-[200px] bottom-[-63px] box-border border-r-[1px] border-solid border-gray-1000" />
          <div className="flex-1 flex flex-row items-start justify-between max-w-full gap-[20px] mq1050:flex-wrap">
            <div className="flex flex-col items-start justify-start py-0 pr-11 pl-0 gap-[25px]">
              <div className="relative tracking-[0.08em] leading-[20px] uppercase font-semibold inline-block min-w-[73px] mq450:text-base mq450:leading-[16px]">{`Resume `}</div>
              <div className="flex flex-col items-start justify-start gap-[8px] text-base">
                <div className="relative leading-[26px] inline-block min-w-[98px]">
                  Resume Builder
                </div>
                <div className="relative leading-[26px] inline-block min-w-[119px]">
                  Resume Templates
                </div>
                <div className="relative leading-[26px] inline-block min-w-[105px]">
                  Resume Formats
                </div>
                <div className="relative leading-[26px]">
                  Professional Resume Writers
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start justify-start gap-[25px]">
              <div className="relative tracking-[0.08em] leading-[20px] uppercase font-semibold mq450:text-base mq450:leading-[16px]">
                Cover Letter
              </div>
              <div className="flex flex-col items-start justify-start gap-[8px] text-base">
                <div className="relative leading-[26px] text-left">
                  What is a cover letter
                </div>
                <div className="relative leading-[26px]">
                  Cover Letter Templates
                </div>
                <div className="relative leading-[26px]">
                  Cover Letter Formats
                </div>
                <div className="relative leading-[26px]">
                  How to write a Cover Letter
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start justify-start py-0  pl-0">
              <div className="relative tracking-[0.08em] leading-[20px] uppercase font-semibold inline-block min-w-[87px] mq450:text-base mq450:leading-[16px]">
                Support
              </div>
              <div className="flex flex-col items-start justify-start gap-[8px] text-base">
                <div className="relative leading-[26px] inline-block min-w-[39px]">
                  About
                </div>
                <div className="relative leading-[26px] inline-block min-w-[52px]">
                  Contact
                </div>
                <div className="relative leading-[26px] inline-block min-w-[122px]">{`Terms & Conditions`}</div>
                <div className="relative leading-[26px] inline-block min-w-[86px]">
                  Privacy Policy
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start justify-start gap-[25px]">
              <div className="relative tracking-[0.08em] leading-[20px] uppercase font-semibold inline-block min-w-[110px] mq450:text-base mq450:leading-[16px]">
                resources
              </div>
              <div className="flex flex-col items-start justify-start gap-[8px] text-base">
                <div className="relative leading-[26px] inline-block min-w-[29px]">
                  Blog
                </div>
                <div className="relative leading-[26px]">
                  Job Interview Guides
                </div>
                <div className="relative leading-[26px]">
                  Job Interview Questions
                </div>
                <div className="relative leading-[26px] inline-block min-w-[111px]">
                  Career Resources
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="self-stretch h-[78px] box-border flex flex-col items-start justify-start pt-1.5 px-0 pb-7 gap-[27px] shrink-0 z-[2] border-t-[1px] border-solid border-neutrals-n20">
        <div className="self-stretch h-px relative box-border z-[1] border-t-[1px] border-solid border-gray-1100" />
        <div className="self-stretch flex-1 flex flex-row items-start justify-center py-0 px-5">
          <div className="self-stretch w-[156px] relative leading-[16px] font-medium inline-block">
            Copyright © 2024 ResAI
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrameComponent13;
